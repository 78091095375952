// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  platform: 'mobile',
  api: {
    url: 'https://api.communitysportsarenas.co.uk',
    test_url: 'https://api.staging.communitysportsarenas.co.uk',
    client_id: 1,
    client_secret: 'f3lfZUtjtdHru2Kfs1zwdgysQTreFJkhS8p65ydA',
    version: 'v1',
    venue_id: null,
    /////////////////////////////////////////
  },
  inEnvFile: true,
  settings: {
    venue: {},
    deposit: {
      venue_deposit_type: 'percentage',
      venue_deposit_amount: 50,
      venue_takes_deposit: 'yes'
    }, 
    availability: {
      available_website: true,
      available_mobile_app: true,
    },
    app: {
      logo: 'assets/default-logo.svg',
      app_brand_name: 'Community Sports Arenas',
      home_page_title: 'Community Sports Arenas',
      home_page_subtitle: 'Supporting local people through local venues',
      default_image: 'https://admin.communitysportsarenas.co.uk/storage/photos/shares/664295b19651d.png',
      has_about_us_page: 'no',
      has_gallery_page: 'no',
      colour: '#ffffff',
      seo_default_title: 'Community Sports Arenas - Powered by BeCreative.',
      seo_default_description: '',
      seo_default_keywords: '',
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
