import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private headers: any;

  app_version: string = "v1";

  public base_url = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private environment: EnvironmentService,
  ) {
    // let headers = {
    //   'Accept': 'application/json',
    //   'Content-Type': 'application/json'
    // };
    let headers = {};
    
    let url = localStorage.getItem('url');

    if(url == null){
      url = this.environment.api.url;
    }

    this.base_url.next(url);

    this.headers = new HttpHeaders(headers);
  }

  get(endpoint, params = {}, external = false) {

    if (!external) {

      endpoint = this.getFullUrl(endpoint);

    }

    // console.log('header =======> ', this.headers);

    return this.http.get(endpoint, {
      headers: this.headers,
      params
    });
  }
  
  post(endpoint, params = {}) {
    
    // console.log('header =======> ', this.headers);

    return this.http.post(this.getFullUrl(endpoint), params, {
      headers: this.headers
    });
  }

  delete(endpoint, params = {}) {
    return this.http.delete(this.getFullUrl(endpoint), {
      params,
      headers: this.headers,
    });
  }

  private getFullUrl(endpoint) {

    var clear_url_at = parseInt(localStorage.getItem('clear_url_at'));
    
    var now  = new Date().getTime();

    // console.log(clear_url_at + ' < ' + now);

    if( clear_url_at < new Date().getTime()){

      this.getApiUrl();

      // console.log('GET API URL');

    }

    if (endpoint.charAt(0) !== '/') {
      endpoint = '/' + endpoint;
    }

    let url = this.base_url.getValue();

    this.base_url.subscribe((value) => {
      url = value;
    });


    // console.log(url + endpoint);

    return url + endpoint;
  }

  getApiUrl(){

    this.get("https://api-check.communitysportsarenas.co.uk?version="+ encodeURIComponent(this.app_version), null, true).subscribe((url: string) => {

      localStorage.removeItem('url');
      localStorage.removeItem('clear_url_at');

      var now  = new Date().getTime();

      localStorage.setItem('url', url);
      localStorage.setItem('clear_url_at', (now + (1  * 24 * 60 * 60 * 1000)).toString());

      this.setBaseUrl(url);

    });

  }

  public setBaseUrl(url) {
    // console.log(url);
    this.base_url.next(url);
  }

}




// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Injectable } from '@angular/core';
// import { BehaviorSubject} from 'rxjs';
// import { EnvironmentService } from '../environment/environment.service';

// @Injectable({
//   providedIn: 'root',
// })
// export class ApiService {
//   private headers: HttpHeaders;
//   app_version: string = '1.0.0';
//   public base_url = new BehaviorSubject<string>(null);

//   constructor(
//     private http: HttpClient,
//     private environment: EnvironmentService
//   ) {
//     this.headers = new HttpHeaders({});
//     this.initializeApiUrl();
    
//   }

//   async initializeApiUrl() {
//     let url = localStorage.getItem('url');

//     if (!url) {
//       try {
//         url = await this.getApiUrl();
//         console.log('Initialized URL:', url);
//         this.saveUrlToLocalStorage(url);
       
//       } catch (error) {
//         console.error('Error fetching API URL:', error);
//         url = this.environment.api.url; // Fallback to the default URL
//         this.saveUrlToLocalStorage(url);
//       }
      
//     }

//     this.setBaseUrl(url);
//   }

//   async getApiUrl(): Promise<string> {
//       const endpoint = `https://api-check.communitysportsarenas.co.uk?version=${encodeURIComponent(this.app_version)}`;
//     try {
//       const url = await this.http.get<string>(endpoint).toPromise();
//       console.log('Fetched URL:', url);
//       this.saveUrlToLocalStorage(url)
//       return url;
//     } catch (error) {
//       console.error('Error fetching API URL:', error);
//       throw error;
//     }
//   }

//   saveUrlToLocalStorage(url: string) {
//     const now = new Date().getTime();
//     localStorage.setItem('url', url);
//     localStorage.setItem('clear_url_at', (now + 1 * 24 * 60 * 60 * 1000).toString()); // 1 day expiration
//   }

//   get(endpoint, params = {}, external = false) {
//     if (!external) {
//       endpoint = this.getFullUrl(endpoint);
//     }
//     if(this.checkIfUrl()){
//       return this.http.get(endpoint, {
//         headers: this.headers,
//         params,
//       });
//     }
//   }

//   post(endpoint, params = {}) {

//     if(this.checkIfUrl()){
//       return this.http.post(this.getFullUrl(endpoint), params, {
//         headers: this.headers,
//       });
//     }    
//   }
 

//   delete(endpoint: string, params = {}) {
//     if(this.checkIfUrl()){
//       return this.http.delete(this.getFullUrl(endpoint), {
//         params,
//         headers: this.headers,
//       });
//     }
//   }

//   async checkIfUrl(){
//     let url = localStorage.getItem('url');
//     if (!url) {
//       try {
//         url = await this.getApiUrl();
//         console.log('Initialized URL:', url);
//         return true
//       } catch (error) {
//         console.error('Error fetching API URL:', error);
//         url = this.environment.api.url;
//         return false
//       }
//     }
//   }

//   getFullUrl(endpoint: string): string {
//     const clear_url_at = parseInt(localStorage.getItem('clear_url_at') || '0');
//     const now = new Date().getTime();

//     if (clear_url_at < now) {
//       this.initializeApiUrl();
//     }

//     if (endpoint.charAt(0) !== '/') {
//       endpoint = '/' + endpoint;
//     }

//     return this.base_url.getValue() + endpoint;
//   }

//   setBaseUrl(url: string) {
    
//     this.base_url.next(url);
//   }
// }